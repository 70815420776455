exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-fly-io-mdx": () => import("./../../../src/pages/blog/fly-io.mdx" /* webpackChunkName: "component---src-pages-blog-fly-io-mdx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-uses-tsx": () => import("./../../../src/pages/uses.tsx" /* webpackChunkName: "component---src-pages-uses-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-flyio-hosting-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/joneshshrestha/Desktop/GitHub/portfolio-website/content/blog/flyio-hosting.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-flyio-hosting-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-lighting-network-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/joneshshrestha/Desktop/GitHub/portfolio-website/content/blog/lighting-network.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-lighting-network-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-post-1-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/joneshshrestha/Desktop/GitHub/portfolio-website/content/blog/post-1.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-post-1-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-pages-blog-fly-io-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/joneshshrestha/Desktop/GitHub/portfolio-website/src/pages/blog/fly-io.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-pages-blog-fly-io-mdx" */)
}

